* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.border-radius-5 {
	border-radius: 5px;
}
.w-10 {
	width: 10%;
}
.w-20 {
	width: 20%;
}
.nowrap {
	white-space: nowrap;
}
.cursor-pointer {
	cursor: pointer;
}
.card {
	transition: 0.2s;
}
/* .card:hover {
  transform: scale(1.01);
  transition: 0.2s;
} */
.active-ticker {
	border-color: green !important;
}
.error-bordered {
	border: 1px solid red !important;
}
.disabled {
	pointer-events: none;
	opacity: 0.4;
}
.next-button,
.save-button {
	height: 40px;
	width: 150px;
}
@keyframes spinner {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate(0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate(360deg);
	}
}
.spin::before {
	animation: 1.5s linear infinite spinner;
	animation-play-state: inherit;
	border: solid 2px #fff;
	border-bottom-color: transparent;
	border-radius: 50%;
	content: "";
	width: 25px;
	height: 25px;
	position: absolute;
	transform: translate3d(-50%, -50%, 0);
	will-change: transform;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: #e9ecef;
	border-radius: 0.3rem;
	margin-top: 3.5rem;
}
@media (min-width: 576px) {
	.jumbotron {
		padding: 4rem 2rem;
	}
}
.fw-500 {
	font-weight: 500;
}
.loader-line {
	width: 100%;
	height: 4px;
	left: 0;
	position: fixed;
	overflow: hidden;
	background-color: #ddd;
}
.loader-line:before {
	content: "";
	position: absolute;
	left: -50%;
	height: 100%;
	width: 40%;
	background-color: #007bff;
	-webkit-animation: lineAnim 1s linear infinite;
	-moz-animation: lineAnim 1s linear infinite;
	animation: lineAnim 1s linear infinite;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}
@keyframes lineAnim {
	0% {
		left: -40%;
	}
	50% {
		left: 20%;
		width: 80%;
	}
	100% {
		left: 100%;
		width: 100%;
	}
}
.not_rounded.list-group-item:first-child {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.switch-checkbox {
	height: 0;
	width: 0;
	visibility: hidden;
	display: none;
}

.switch-label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	width: 60px;
	height: 30px;
	background: grey;
	border-radius: 60px;
	position: relative;
	transition: background-color 0.2s;
}

.switch-label .switch-button {
	content: "";
	position: absolute;
	top: 2px;
	left: 2px;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	transition: 0.3s;
	background: #fff;
	box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked + .switch-label .switch-button {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}
.h-full {
	min-height: calc(100vh - 35px);
}
.footer {
	background-color: #212529;
	height: 35px;
}

/* .switch-label:active .switch-button {
	width: 50px;
} */
